.contactMain {
    height: 100vh;
    background-color: #2a4849;
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f2e9e4;
    letter-spacing: 1px;
}
.contactMain h1 {
    font-size: 40px;
    font-family: 'Abril Fatface', cursive;
    letter-spacing: 2px;
    color: #e47545;
}
.contactSpan {
    color: #e1a647
}
.contactForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.contactToFromDiv {
    display: flex;
} 
.contactFromInput,
.contactEmailInput,
.contactMessageInput {
    height: 30px;
    margin: 4px;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    padding: 6px 10px;
    border: 3px solid #f2e9e4;
    border-radius: 5px;
    background-color: rgb(228, 228, 228);
    color: black;
    font-weight: 800;
    width: 50%;
    line-height: 1.5;
    letter-spacing: 1px;
}
.contactMessageInput {
    height: 70px;
}

.contactSubmitBtn {
    background-color: #a2ad91;
    width: 160px;
    align-self: center;
    margin-top: 30px;
    padding: 10px 0;
    border-radius: 10px;
    /* border: solid 2px #f2e9e4; */
    border: none;
    color: #2a4849;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
}
.contactSubmitBtn:hover {
    cursor: pointer;
    opacity: .75;
}

@media screen and (max-width: 767px) {
    .contactForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contactToFromDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .contactFromInput {
        width: 80%;
        align-self: center;
        justify-self: center;
    }
    .contactToInput {
        width: 80%;
    }
    .contactEmailInput {
        width: 80%;
    }
    .contactMessageInput {
        width: 80%;
    }
}