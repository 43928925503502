.projectsMain {
    height: auto;
    display: flex;
    background-color: #a2ad91;
}

.projectsTitleUl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    align-items: flex-start;
    position: sticky;
    top: 0;
    width: 15vw;
    height: 100vh;
    background-color: rgb(10, 10, 10);
    /* font-family: 'Abril Fatface', cursive; */
    font-family: 'Fredericka the Great', cursive;
    color: #e47545;
    font-size:35px;
}
.projectsTitleUl li {
    padding: 0 0 2vh 5vw;
    margin: 0;
}
.projectsAlternateLetter {
    color: #e1a647;
}

.projectsUl {
    font-family: 'Nunito', sans-serif;
    list-style: none;
    width: 80vw;
}

.projectsUl {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 60px 0;
    height: auto;
    padding: 0;
}
.projectsLiLeft li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.projectsLiRight li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projectsCardH3 {
   color: #f2e9e4;
   text-decoration: none;
   padding: 0;
   margin: 30px 0 10px 0;
   font-family: 'Abril Fatface', cursive;
   font-size: 30px;
   letter-spacing: 2px;
}
.projectsIcon {
    color: #e47545;
    font-size: 30px;
    text-decoration: none;
    margin-bottom: 20px;
}

.projectsCardP {
    color: #f2e9e4;
    text-decoration: none;
    width: 80%;
    word-wrap: normal;
    padding: 20px 0;
    text-align: center;
}
.projectsCardUl {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.projectsCardLi {
    background-color: #e1a647;
    color: #2a4849;
    padding: 3px 5px;
    margin: 5px;
    border-radius: 5px;

}


/* large screens */
@media screen and (min-width: 768px) {
    .projectsLiLeft {
        border-radius: 10px;
        width: 400px;
        height: auto;
        margin: 3vh 10vw;
        align-self: flex-end;
        background-color: #2a4849;
        box-shadow: 5px 5px 5px gray;
        text-decoration: none;
    }
    .projectsLiRight {
        border-radius: 10px;
        height: auto;
        width: 400px;
        margin: 3vh 10vw;
        align-self: flex-start;
        background-color: #667b6d;
        box-shadow: 5px 5px 5px gray;
        text-decoration: none;
    }
}

/* small screens */
@media screen and (max-width: 767px) {
    .projectsLiLeft, .projectsLiRight {
        background-color: #2a4849;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;
        width: 90%;
        align-self: center;
        margin: 20px 0;
        box-shadow: 5px 5px 5px gray;
        border-radius: 10px;
        text-decoration: none;
    }
    .projectsLiRight {
        background-color: #667b6d;
    }
}