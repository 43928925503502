/* .projectRoutePage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
} */

.projectRouteMain {
    background-color: #dad2bc;
    font-family: 'Nunito', sans-serif;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
}

.projectRouteHeader {
    font-family: 'Nunito', sans-serif;
    position: absolute;
    height: 60px;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(10,10,10);
    display: flex;
    align-items: center;
}
.projectRouteBackButton {
    color: #f2e9e4;
    font-size: 22px;
    margin-left: 30px;
    text-decoration: none;
}
.projectRouteHeaderIcon {
    font-size: 18px;
    margin: 0 5px;
    padding: 0

}
.projectRouteTitleDiv {
    background-color: #667b6d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    color:#f2e9e4
}
.projectRouteTitle {
    /* font-family: 'Abril Fatface', cursive; */
    font-family: 'Fredericka the Great', cursive;
    letter-spacing: 2px;
}

.projectRouteTitleLink {
    font-size: 25px;
    padding: 10px;
    color: #e1a647;
}
.projectRouteTitleLink:hover {
    color: #e47545;
}

.projectRouteInfoTop {
    height: auto;
    margin: 1rem auto;
    max-width: 1600px;
}
.projectRouteDescriptionDiv {
    color: #f2e9e4;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projectRouteDescriptionDiv p {
    width: 90%;
}
.projectRouteDescriptionSpan {
    color: #2a4849;
}
.projectRouteChallengeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 2px #2a4849;
    color: #2a4849;
}
.projectRouteChallengeDiv h2 {
    text-align: center;
    padding: 0;
    margin: 1.5rem 0 0 0;
}
.projectRouteChallengeDiv p {
    padding: 1.5rem;
    text-align: justify;
    margin: 0;
    line-height: 1.5;
    letter-spacing: 1px;
}
.projectRouteInfoBottom {
    height: auto;
    margin: 1rem auto;
    max-width: 1600px;
}
.projectRouteStackLinkDiv {
    display: flex;
    flex-direction: column;
}
.projectRouteSolutionDiv {
    background-color: #2a4849;
    border: solid 2px #2a4849;
    color: #f2e9e4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.projectRouteSolutionDiv h2 {
    text-align: center;
    padding: 0;
    margin: 1.5rem 0 0 0;
}
.projectRouteSolutionDiv p {
    padding: 1.5rem;
    text-align: justify;
    margin: 0;
    line-height: 1.5;
    letter-spacing: 1px;
}
.projectRouteStackDiv {
    width: 100%;
    background-color: #2a4849;
    border: solid 2px #2a4849;
    color: #f2e9e4;
    margin-bottom: 1rem;
    height: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.projectRouteStackDiv h3 {
    text-align: center;
    padding: 0 0 10px 0;
    margin: 0;
}
.projectRouteStackUl {
    list-style: none;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}
.projectRouteStackUl li {
    background-color: #e1a647;
    border-radius: 5px;
    color: rgb(10,10,10);
    padding: 3px;
    margin: 3px;
    width: auto;
    font-size: 12px;
}
.projectRouteLinkDiv {
    width: 100%;
    border: 2px solid #e47545;
    color: #2a4849;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projectRouteLinkDiv a {
    font-size: 40px;
    color: #e47545;
    padding: 20px;
}
.projectRouteLinkDiv a:hover {
    color: #e1a647;
}



/* large screens */
@media screen and (min-width: 768px) {
    .projectRouteTitle {
        padding: 0;
        margin: 0;
        font-size: 40px;
    }
    .projectRouteSubtitle {
        padding: none;
        margin: 10px 0;
        font-size: 20px;
    }
    .projectRouteInfoTop {
        display: flex;
        flex-direction: row;
    }
    .projectRouteDescriptionDiv {
        height: auto;
        width: 38%;
        background-color: #e47545;
        border: solid 2px #e47545;
        margin-right: 1rem;
     }
     .projectRouteChallengeDiv {
        width: 60%;
     }
     .projectRouteInfoBottom {
        display: flex;
     }
     .projectRouteStackLinkDiv {
        height: auto;
        width: 38%;
        margin-right: 1rem;
     }
     .projectRouteSolutionDiv {
        width: 60%;
     }
    

}

/* small screens */
@media screen and (max-width: 767px) {
    .projectRouteTitle {
        padding: 0;
        margin: 0;
        font-size: 30px;
    }
    .projectRouteSubtitle {
        padding: none;
        margin: 10px 0;
        font-size: 18px;
    }
    .projectRouteInfoTop {
        display: flex;
        flex-direction: column;
    }
    .projectRouteDescriptionDiv {
        width: 100%;
        background-color: #e47545;
        border: solid 2px #e47545;
        margin-bottom: 1rem;
     }
     .projectRouteChallengeDiv {
        width: 100%;
     }
     .projectRouteInfoBottom {
        display: flex;
        flex-direction: column;
     }
     .projectRouteStackLinkDiv {
        width: 100%;
        margin-bottom: 1rem;
     }
     .projectRouteStackDiv {
        padding: 25px 0;
     }
     .projectRouteSolutionDiv {
        width: 100%;
     }
}