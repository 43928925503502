.aboutMain  {
    height: auto;
    min-height: 100vh;
    background-color: rgb(10, 10, 10);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 65px 0 30px 0;
}

.aboutWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 80%;
    color: #f2e9e4;
    font-family: 'Nunito', sans-serif;
}

.aboutInfoDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.aboutInfoSubtitle {
    color: #e1a647;
    padding: 5px;
    margin: 0;
    letter-spacing: 1px;
}
.aboutInfoParagraph {
    padding: 0 0 20px 0;
    margin: 5px;
    text-align: justify;
    line-height: 1.5;
    letter-spacing: 1px;
}
.aboutInfoButton {
    text-decoration: none;
    color: #f2e9e4;
    /* border: solid 2px #f2e9e4; */
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #e47545;
    width: 160px;
    text-align: center;
    align-self: center;
    letter-spacing: 1px;
}
.aboutInfoButton:hover {
    color: #f2e9e4;
    opacity: .75;
    cursor: pointer;
}

.aboutImg {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    box-shadow: 3px 3px 3px rgb(44, 44, 44);
    filter: saturate(30%);
    margin-bottom: 30px;
}
.aboutSocialLinksDiv {
    display: flex;
    width: 100%;
    align-items: space-around;
    justify-content: space-evenly;
    padding-bottom:  20px;
}
.aboutSocialLinksDiv a {
    font-size: 30px;
    color: #667b6d;
}
.aboutSocialLinksDiv:hover {
    color: #e47545;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .aboutImg {
        width: 200px;
        height: 200px;
    }    
}

@media screen and (max-width: 767px) {
    .aboutWrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .aboutInfoDiv {
        width: 90%;
    }
}