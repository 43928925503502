.headerFullDiv {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: rgb(10, 10, 10);
    height: 60px;
    z-index: 1000;
}
.headerFullLogoDiv {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.headerFullLogo {
    color: #667b6d;
    font-size: 30px;
    padding: 10%;
    margin-left: 20px;
}
.headerFullLogo:hover {
    color: #f2e9e4;
}
.headerFullNav {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}
.headerFullNav a {
    color: #f2e9e4;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    text-decoration: none;
    margin: 10px;
}

/* header collapsed styles */

.headerCollapsedDiv {
    background-color: rgb(10, 10, 10);
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: sticky;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
}

.headerCollapsedLogo {
    color: #9a8c98;
    font-size: 30px;
    margin-left: 25px;
}
.headerCollapsedLogo:hover {
    color: #f2e9e4;
}

.headerCollapsedBtnOpen {
    color: #f2e9e4;
    background-color: black;
    border: solid 1px #f2e9e4;
    border-radius: 5px;
    padding: 8px;
    margin-right: 20px;
}
.headerCollapsedBtnOpen:hover {
    cursor: pointer;
    opacity: .75;
}

.headerCollapsedShow {
    position: absolute;
    background-color: #f2e9e4;
    z-index: 99;
    right: 3px;
    top: 60px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    padding: 10px 0;
}
.headerCollapsedShow a {
    text-decoration: none;
    text-transform: capitalize;
    color: black;
    padding: 10px;
    width: 25vw;
    font-size: 18px;
    margin-left: 5%;
}

.headerCollapsedHide {
    display: none;
}

/*large screens */
@media screen and (min-width: 768px) {
    .headerCollapsedDiv {
        display: none;
    }
}
/* small screens */
@media screen and (max-width: 767px) {
    .headerFullDiv {
        display: none;
    }
}