.skillsMain {
    min-height: 100vh;
    height: auto;
    background-color: rgb(10, 10, 10);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}
.skillsDesignDiv1 {
    background-color: #e47545;
    position: absolute;
    height: 70vh;
    width: 70%;
    z-index: 1;
    top: 135vh;
    left: 15%;
    border-radius: 50%;
    transform: rotate(20deg);
}
.skillsDesignDiv2 {
    background-color: #667b6d;
    position: absolute;
    height: 70vh;
    width: 70%;
    z-index: 2;
    top: 135vh;
    left: 15%;
    border-radius: 50%;
    transform: rotate(-5deg);
}

.skillsDiv {
    color: rgb(10, 10, 10);
    z-index: 99;
    position: absolute;
    width: 50%;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.skillsTitle {
    font-size: 35px;
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: 'Abril Fatface', cursive;
    color:#2a4849;
}
.skillsParagraph {
    width: 80%;
}

.skillsUl {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.skillsSkill {
    /* border: 2px #e1a647 solid; */
    border-radius: 5px;
    padding: 3px 5px;
    margin: 5px;
    color: rgb(10, 10, 10);
    background-color: #e1a647;
}
.skillsSkill:hover {
    color: rgb(10, 10, 10);
    /* border: solid 2px #e47545; */
    background-color: #e47545;
}

@media screen and (max-width: 880px) {
    .skillsSkill {
        font-size: 14px;
    }
    .skillsTitle {
        color: #e47545;
    }
    .skillsParagraph {
        font-size: 14px;
        padding: 0;
        padding-top: 20px;
        margin: 0;
        color: white;
    }
    .skillsDesignDiv1 {
        display: none;
    }
    .skillsDesignDiv2 {
        display: none;
    }
    .skillsDiv {
        width: 100%;
    }
    .skillsUl {
        width: 80%;
    }
}

