.homeMain {
    background-color: #2a4849;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2e9e4;
}
.homeWrapper {
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.homeTitleDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeTitle {
    /* font-family: 'Abril Fatface', cursive; */
    font-family: 'Fredericka the Great', cursive;
    color: #e1a647;
    font-size: 80px;
    padding: 0;
    margin: 0;
}
.homeTitle2 {
    /* font-family: 'Abril Fatface', cursive; */
    font-family: 'Fredericka the Great', cursive;
    color: #e47545;
    font-size: 80px;
    padding: 0;
    margin: 0;
}
.homeInfo {
    font-size: 14px;
}
.homeDescriptionDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.homeDescription {
    font-size: 25px;
    padding: 10px 0;
    margin: 0;
}
.homeDescriptionGreeting {
    font-size: 30px;
}

@media screen and (max-width: 1000px) {
    .homeWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: auto;
        padding-right: 10%;
    }
    .homeTitleDiv {
        padding-left: 5%;
    }
    .homeDescriptionDiv {
        padding-left: 5%;
        margin-top: 10%;
        width: 100%;
    }
    .homeDescription {
        font-size: 20px;
    }
    .homeDescriptionGreeting {
        font-size: 25px;
    }
}

@media screen and (max-width: 767px) {
    .homeTitle, .homeTitle2 {
        font-size: 60px;
    }
    .homeDescription {
        font-size: 14px;
    }
    .homeDescriptionGreeting {
        font-size: 20px;
    }
    .homeInfo {
        font-size: 10px;
    }
}