.footerMain {
    background-color: rgb(10, 10, 10);
    font-family: 'Nunito', sans-serif;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}
.footerTop {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.footerBottom {
    color: #f2e9e4;
    font-size: 12px;
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.footerContactLinks,
.footerSocialLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em;
}
.footerContactLinks p {
    padding: 4px;
    margin: 5px 0;
}
.footerSocialLinks {
    align-items: flex-start;
}

.footerLink {
    text-decoration: none;
    color: #f2e9e4;
    font-size: 16px;
    padding: 4px;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 2px;
}

a:hover {
    cursor: pointer;
    color: #667b6d;
}



/*small screens */
@media screen and (max-width: 767px) {
    .footerTop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footerIcon {
        color: #9a8c98;
        font-size: 40px;
    }
}


/* medium and large screens */
@media screen and (min-width: 768px) {
    .footerTop {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        height: 10em;
    }
    .footerIcon { 
        display: none;
    }
}