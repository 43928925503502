html, body {
    margin: 0;
    padding: 0;
}

.app {
    background-color: rgb(189, 189, 189);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
}
